class OsPaymentsBraintree {


  static create_paypal_button(button){
  	let $booking_form_wrapper = jQuery(button).closest('.latepoint-booking-form-element');
  	var payment_amount = latepoint_get_paypal_payment_amount($booking_form_wrapper);
  	
    braintree.client.create({
      authorization: latepoint_helper.braintree_paypal_client_auth
    }).then(function (clientInstance) {
      // Create a PayPal Checkout component.
      return braintree.paypalCheckout.create({
        client: clientInstance
      });
    }).then(function (paypalCheckoutInstance) {
      // Set up PayPal with the checkout.js library
      return paypal.Button.render({
      	style: {
      		label: 'pay',
      		size: 'large',
      		shape:   'rect',
      		tagline: false,
      		color: 'gold'
      	},
        env: latepoint_helper.braintree_paypal_environment_name, // or 'sandbox'

        payment: function () {
          return paypalCheckoutInstance.createPayment({
            flow: 'checkout', // Required
            amount: payment_amount, // Required
            currency: latepoint_helper.paypal_payment_currency,
            intent: 'authorize' // Required
            // Your PayPal options here. For available options, see
            // http://braintree.github.io/braintree-web/current/PayPalCheckout.html#createPayment
          });
        },


        onAuthorize: function (data, actions) {
          return paypalCheckoutInstance.tokenizePayment(data)
            .then(function (payload) {
			        latepoint_set_payment_token_and_submit($booking_form_wrapper, payload.nonce);
            });
        },

        onCancel: function (data) {
          // console.log('checkout.js payment cancelled', JSON.stringify(data, 0, 2));
        },

        onError: function (err) {
          console.error('checkout.js error', err);
        }
      }, button);
    }).then(function () {
      // The PayPal button will be rendered in an html element with the id
      // `paypal-button`. This function will be called when the PayPal button
      // is set up and ready to be used.
    }).catch(function (err) {
      console.error('Error!', err);
      // Handle component creation error
    });
  }

	static create_token($booking_form_wrapper){
	  // Gather additional customer data we may have collected in our form.
	  var name = jQuery($booking_form_wrapper).find('#payment_name_on_card');
	  var zip = jQuery($booking_form_wrapper).find('#payment_zip');
	  var additionalData = {
	    name: name ? name.value : undefined,
	    address_zip: zip ? zip.value : undefined,
	  };

	  OsPaymentsBraintree.hostedFieldsInstance.tokenize(function (tokenizeErr, payload) {
	    if (tokenizeErr) {
	    	if(tokenizeErr.code == 'HOSTED_FIELDS_FIELDS_INVALID' && tokenizeErr.details.invalidFields){
	    		jQuery.each(tokenizeErr.details.invalidFields, function(key, field){
	    			jQuery(field).addClass('braintree-hosted-fields-invalid');
	    		});
	    	}
	      latepoint_show_message_inside_element(tokenizeErr.message);
	      jQuery($booking_form_wrapper).find('.latepoint-next-btn').removeClass('os-loading');
	    }else{
	      // If we received a token, show the token ID.
        latepoint_set_payment_token_and_submit($booking_form_wrapper, payload.nonce);
	    }
	  });
	}




	static init_cc_form(){
		braintree.client.create({
		  authorization: latepoint_helper.braintree_tokenization_key
		}, function(err, clientInstance) {
		  if (err) {
		    console.error(err);
		    return;
		  }

		  braintree.hostedFields.create({
		    client: clientInstance,
		    styles: {
		      'input': {
		        'font-size': '14px',
		        'font-family': latepoint_helper.body_font_family,
		        'font-weight': '500',
		        'color': '#fff',
		      },
		      ':focus': {
		        'color': '#fff'
		      },
		      '::placeholder': {
		        'color': '#7d89b1'
		      },
		      '.valid': {
		        'color': '#fff'
		      },
		      '.invalid': {
		        'color': '#ff5a16'
		      }
		    },
		    fields: {
		      number: {
		        selector: '#payment_card_number',
		        placeholder: jQuery('#payment_card_number').data('placeholder')
		      },
		      cvv: {
		        selector: '#payment_card_cvc',
		        placeholder: jQuery('#payment_card_cvc').data('placeholder')
		      },
		      expirationDate: {
		        selector: '#payment_card_expiration',
		        placeholder: jQuery('#payment_card_expiration').data('placeholder')
		      },
		    }
		  }, function(err, hostedFieldsInstance) {
		  	OsPaymentsBraintree.hostedFieldsInstance = hostedFieldsInstance;
		    if (err) {
		      console.error(err);
		      return;
		    }
		  });
		});
	}
}