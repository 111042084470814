class OsPaymentsPaypal {


  static create_paypal_button(button){
  	let $booking_form_wrapper = jQuery(button).closest('.latepoint-booking-form-element');
  	var payment_amount = latepoint_get_paypal_payment_amount($booking_form_wrapper);

	  paypal.Buttons({
	    createOrder: function(data, actions) {
	      return actions.order.create({
	        purchase_units: [{
	          amount: {
	            value: payment_amount,
	            currency_code: latepoint_helper.paypal_payment_currency,
	          },
	        }],
	        application_context: {
		        shipping_preference: 'NO_SHIPPING'
	        }
	      });
	    },
	    onApprove: function(data, actions) {

				jQuery($booking_form_wrapper).removeClass('step-content-loaded').addClass('step-content-loading');
	      // Authorize the transaction
	      actions.order.authorize().then(function(authorization) {

	        // Get the authorization id
	        var authorizationID = authorization.purchase_units[0].payments.authorizations[0].id
          var orderID = data.orderID;
	        latepoint_set_payment_token_and_submit($booking_form_wrapper, authorizationID);
	      });
	    }
	  }).render(button);
  }
}