// @codekit-prepend "os-time.js"
// @codekit-prepend "os-shared.js"
// @codekit-prepend "os-notifications.js";
// @codekit-prepend "os-actions.js"
// @codekit-prepend "os-payments-braintree.js"
// @codekit-prepend "os-payments-paypal.js"


  function show_next_btn($booking_form_wrapper){
    $booking_form_wrapper.find('.latepoint-next-btn').removeClass('disabled');
    $booking_form_wrapper.removeClass('hidden-buttons');
  }

  function clear_service_sub_values($item){
    var $booking_form_wrapper = $item.closest('.latepoint-booking-form-element');
    latepoint_update_summary_field($booking_form_wrapper, 'duration', '');
    latepoint_update_summary_field($booking_form_wrapper, 'service-extras', '');
    latepoint_update_summary_field($booking_form_wrapper, 'total-attendies', '');
    $booking_form_wrapper.find('.latepoint_service_extras_price').val('');
    $booking_form_wrapper.find('.latepoint_service_extras_ids').val('');
    $booking_form_wrapper.find('.latepoint_duration').val('');
    $booking_form_wrapper.find('.latepoint_total_attendies').val(1);
    $booking_form_wrapper.find('.quantity-selector-input').val(1);
  }


  function hide_next_btn($booking_form_wrapper){
    $booking_form_wrapper.find('.latepoint-next-btn').addClass('disabled');
    if($booking_form_wrapper.find('.latepoint-prev-btn.disabled').length) $booking_form_wrapper.addClass('hidden-buttons');
  }


  function show_prev_btn($booking_form_wrapper){
    $booking_form_wrapper.find('.latepoint-prev-btn').removeClass('disabled');
    $booking_form_wrapper.removeClass('hidden-buttons');
  }

  function hide_prev_btn($booking_form_wrapper){
    $booking_form_wrapper.find('.latepoint-prev-btn').addClass('disabled');
    if($booking_form_wrapper.find('.latepoint-next-btn.disabled').length) $booking_form_wrapper.addClass('hidden-buttons');
  }


function latepoint_show_capacity_selector($item){
  var $booking_form_wrapper = $item.closest('.latepoint-booking-form-element');
  var max_capacity = $item.data('max-capacity');
  $booking_form_wrapper.find('.sta-sub-label span').text(max_capacity);
  $booking_form_wrapper.find('.step-services-w').removeClass('selecting-service-duration');
  $booking_form_wrapper.find('.quantity-selector-w').data('max-capacity', max_capacity);
  $item.closest('.step-services-w').addClass('selecting-total-attendies');
  show_prev_btn($booking_form_wrapper);
  show_next_btn($booking_form_wrapper);
}


function latepoint_show_durations($item){
  var $booking_form_wrapper = $item.closest('.latepoint-booking-form-element');
  $item.closest('.step-services-w').addClass('selecting-service-duration');
  show_prev_btn($booking_form_wrapper);
  hide_next_btn($booking_form_wrapper);
}

function latepoint_apply_coupon($elem){
  var $booking_form_wrapper = $elem.closest('.latepoint-booking-form-element');

  var $coupon_input = $elem;
  $coupon_input.closest('.coupon-code-input-w').addClass('os-loading');
  var form_params = $booking_form_wrapper.find('.latepoint-form').serialize();
  var data = { action: 'latepoint_route_call', route_name: $elem.data('route'), params: form_params, layout: 'none', return_format: 'json' }
  jQuery.ajax({
    type : "post",
    dataType : "json",
    url : latepoint_helper.ajaxurl,
    data : data,
    success: function(data){
      $coupon_input.closest('.coupon-code-input-w').removeClass('os-loading');
      if(data.status === "success"){
        latepoint_show_message_inside_element(data.message, $booking_form_wrapper.find('.latepoint-body'), 'success');
        latepoint_reload_step($booking_form_wrapper);
      }else{
        latepoint_show_message_inside_element(data.message, $booking_form_wrapper.find('.latepoint-body'), 'error');
      }
    }
  });
}

function latepoint_coupon_removed($elem){
  $elem.closest('.applied-coupon-code').fadeOut();
  var $booking_form_wrapper = $elem.closest('.latepoint-booking-form-element');
  latepoint_reload_step($booking_form_wrapper);
}

function latepoint_reload_step($booking_form_wrapper){

  $booking_form_wrapper.find('.latepoint_step_direction').val('specific');
  $booking_form_wrapper.find('.latepoint-form').submit();

  return false;
}


function latepoint_get_new_prev_payment_class($payment_step, new_current_payment_step_class){
  var new_prev_payment_step_class = '';
  switch(new_current_payment_step_class){
    case 'lp-show-pay-times':
      new_prev_payment_step_class = '';
    break;
    case 'lp-show-pay-methods':
      if($payment_step.find('.lp-payment-times-w').length){
        new_prev_payment_step_class = 'lp-show-pay-times';
      }else{
        new_prev_payment_step_class = latepoint_get_new_prev_payment_class($payment_step, 'lp-show-pay-times');
      }
    break;
    case 'lp-show-pay-portion-selection':
      if($payment_step.find('.lp-payment-methods-w').length){
        new_prev_payment_step_class = 'lp-show-pay-methods';
      }else{
        new_prev_payment_step_class = latepoint_get_new_prev_payment_class($payment_step, 'lp-show-pay-methods');
      }
    break;
    case 'lp-show-card':
    case 'lp-show-paypal':
      if($payment_step.find('.lp-payment-portion-selection-w').length){
        new_prev_payment_step_class = 'lp-show-pay-portion-selection';
      }else{
        new_prev_payment_step_class = latepoint_get_new_prev_payment_class($payment_step, 'lp-show-pay-portion-selection');
      }
    break;
  }
  return new_prev_payment_step_class;
}


function latepoint_reset_password_from_booking_init(){
  jQuery('.os-step-existing-customer-login-w').hide();
  jQuery('.os-password-reset-form-holder').on('click', '.password-reset-back-to-login', function(){
    jQuery('.os-password-reset-form-holder').html('');
    jQuery('.os-step-existing-customer-login-w').show();
    return false;
  });
}

function latepoint_update_summary_field($form, field_name, value){
  var $summary_holder = $form.closest('.latepoint-with-summary');
  if(!$summary_holder.length) return;
  value = String(value).trim();
  if(value){
    $form.find('.os-summary-value-'+field_name).text(value).closest('.os-summary-line').addClass('os-has-value');
    if($summary_holder.hasClass('latepoint-summary-is-open')){
      $form.find('.os-summary-line.os-has-value').slideDown(150);
    }else{
      $form.find('.os-summary-line.os-has-value').fadeIn(200);
    }
    $summary_holder.addClass('latepoint-summary-is-open');
  }else{
    $form.find('.os-summary-value-'+field_name).text('').closest('.os-summary-line').slideUp(150).removeClass('os-has-value');
  }
}

function latepoint_password_changed_show_login(response){
  jQuery('.os-step-existing-customer-login-w').show();
  jQuery('.os-password-reset-form-holder').html('');
  latepoint_show_message_inside_element(response.message, jQuery('.os-step-existing-customer-login-w'), 'success');
}

function latepoint_hide_message_inside_element($elem = jQuery('.latepoint-body')){
  if($elem.length && $elem.find('.latepoint-message').length){
    $elem.find('.latepoint-message').remove();
  }
}

function latepoint_show_message_inside_element(message, $elem = jQuery('.latepoint-body'), message_type = 'error'){
  if($elem.length){
    if($elem.find('.latepoint-message').length){
      $elem.find('.latepoint-message').removeClass('latepoint-message-success').removeClass('latepoint-message-error').addClass('latepoint-message-'+ message_type +'').html(message).show();
    }else{
      $elem.prepend('<div class="latepoint-message latepoint-message-'+ message_type +'">' + message + '</div>');
    }
  }
}

function latepoint_clear_step_vars(step_name, $form){
  switch(step_name) {
    case 'locations':
      $form.find('input[name="booking[start_date]"]').val('');
      break;
    case 'services':
      $form.find('input[name="booking[service_id]"]').val('');
      break;
    case 'agents':
      $form.find('input[name="booking[agent_id]"]').val('');
      break;
    case 'datepicker':
      $form.find('input[name="booking[start_date]"]').val('');
      $form.find('input[name="booking[start_time]"]').val('');
      break;
  }
}

function latepoint_set_payment_token_and_submit($booking_form_wrapper, token){
  $booking_form_wrapper.find('input[name="booking[payment_token]"]').val(token);
  var $booking_form = $booking_form_wrapper.find('.latepoint-form');
  $booking_form.find('.latepoint_step_direction').val('next').submit();
}

( function( $ ) {
  "use strict";

  var latepoint_stripe;
  var latepoint_stripe_elements;
  var latepoint_stripe_elements_card_number;
  var latepoint_stripe_elements_card_expiry;
  var latepoint_stripe_elements_card_cvc;

  function latepoint_register_stripe_card_elements(elements) {

    // Listen for errors from each Element, and show error messages in the UI.
    elements.forEach(function(element) {
      element.on('change', function(event) {
        if (event.error) {
          latepoint_show_message_inside_element(event.error.message);
        } else {
          latepoint_hide_message_inside_element();
        }
      });
    });

  }


  function latepoint_stripe_create_token($booking_form_wrapper){
    // Gather additional customer data we may have collected in our form.
    var name = jQuery($booking_form_wrapper).find('#payment_name_on_card');
    var zip = jQuery($booking_form_wrapper).find('#payment_zip');
    var additionalData = {
      name: name ? name.value : undefined,
      address_zip: zip ? zip.value : undefined,
    };

    // Use Stripe.js to create a token. We only need to pass in one Element
    // from the Element group in order to create a token. We can also pass
    // in the additional customer data we collected in our form.
    latepoint_stripe.createToken(latepoint_stripe_elements_card_number, additionalData).then(function(result) {
      // Stop loading!
      // example.classList.remove('submitting');

      if (result.token) {
        // If we received a token, show the token ID.
        latepoint_set_payment_token_and_submit($booking_form_wrapper, result.token.id);
      } else {
        latepoint_show_message_inside_element(result.error.message);
        jQuery($booking_form_wrapper).find('.latepoint-next-btn').removeClass('os-loading');
      }
    });
  }


  function latepoint_init_stripe_form(){
    latepoint_stripe = Stripe(latepoint_helper.stripe_key);

    latepoint_stripe_elements = latepoint_stripe.elements();


    var elementStyles = {
      base: {
        fontFamily: latepoint_helper.body_font_family,
        fontSize: '14px',
        fontWeight: 500,
        color: '#ffffff',
        '::placeholder': {
          color: '#7d89b1',
        },
      }
    };

    var elementClasses = {
      focus: 'focused',
      empty: 'empty',
      invalid: 'invalid',
    };

    latepoint_stripe_elements_card_number = latepoint_stripe_elements.create('cardNumber', {
      style: elementStyles,
      classes: elementClasses,
      placeholder: jQuery('#payment_card_number').data('placeholder')
    });
    latepoint_stripe_elements_card_number.mount('#payment_card_number');

    latepoint_stripe_elements_card_expiry = latepoint_stripe_elements.create('cardExpiry', {
      style: elementStyles,
      classes: elementClasses,
      placeholder: jQuery('#payment_card_expiration').data('placeholder')
    });
    latepoint_stripe_elements_card_expiry.mount('#payment_card_expiration');

    latepoint_stripe_elements_card_cvc = latepoint_stripe_elements.create('cardCvc', {
      style: elementStyles,
      classes: elementClasses,
      placeholder: jQuery('#payment_card_cvc').data('placeholder')
    });
    latepoint_stripe_elements_card_cvc.mount('#payment_card_cvc');

    latepoint_register_stripe_card_elements([latepoint_stripe_elements_card_number, latepoint_stripe_elements_card_expiry, latepoint_stripe_elements_card_cvc]);
  }

  function latepoint_init_step(step_name, $booking_form_wrapper = false){
    latepoint_init_step_selectable_items();
    latepoint_init_step_items_with_price();
    switch(step_name){
      case 'datepicker':
        latepoint_init_step_datepicker();
      break;
      case 'contact':
        latepoint_init_step_contact();
      break;
      case 'agents':
        latepoint_init_step_agents();
      break;
      case 'locations':
        latepoint_init_step_locations();
      break;
      case 'services':
        latepoint_init_step_services()
      break;
      case 'payment':
        latepoint_init_step_payment($booking_form_wrapper)
      break;
      case 'verify':
        latepoint_init_step_verify($booking_form_wrapper)
      break;
      case 'confirmation':
        latepoint_init_step_confirmation()
      break;
    }
  }



  function day_timeslots($day){
    var $booking_form_wrapper = $day.closest('.latepoint-booking-form-element');
    $day.addClass('selected');

    var service_duration = $day.data('service-duration');
    var interval = $day.data('interval');
    var work_start_minutes = $day.data('work-start-time');
    var work_end_minutes = $day.data('work-end-time');
    var total_work_minutes = $day.data('total-work-minutes');
    var available_minutes = $day.data('available-minutes') ? $day.data('available-minutes').toString().split(',').map(Number) : false;
    var day_minutes = $day.data('day-minutes').toString().split(',').map(Number);

    var $timeslots = $booking_form_wrapper.find('.timeslots');
    $timeslots.html('');

    if(total_work_minutes > 0 && available_minutes.length && day_minutes.length){
      var prev_minutes = false;
      day_minutes.forEach(function(current_minutes){
        if((current_minutes + service_duration) > work_end_minutes) return;
        var ampm = latepoint_am_or_pm(current_minutes);
        if(prev_minutes !== false && ((current_minutes - prev_minutes) > service_duration)){
          // show interval that is off between two work periods
          var off_label = latepoint_minutes_to_hours_and_minutes(prev_minutes + service_duration)+' '+ latepoint_am_or_pm(prev_minutes + service_duration) + ' - ' + latepoint_minutes_to_hours_and_minutes(current_minutes)+' '+latepoint_am_or_pm(current_minutes);
          var off_width = (((current_minutes - prev_minutes - service_duration) / (total_work_minutes + service_duration)) * 100);
          $timeslots.append('<div class="dp-timeslot is-off" style="max-width:'+ off_width +'%; width:'+ off_width +'%"><span class="dp-label">' + off_label + '</span></div>');
        }

        var timeslot_class = 'dp-timeslot';
        if(!available_minutes.includes(current_minutes)){
          timeslot_class+= ' is-booked';
        }
        var tick_html = '';
        if(((current_minutes % 60) == 0) || (interval >= 60)){
          timeslot_class+= ' with-tick';
          tick_html = '<span class="dp-tick"><strong>'+latepoint_minutes_to_hours_preferably(current_minutes)+'</strong>'+' '+ampm+'</span>';
        }
        var timeslot_label = latepoint_minutes_to_hours_and_minutes(current_minutes)+' '+ampm;
        if(latepoint_show_booking_end_time()){
          var end_minutes = current_minutes + service_duration;
          var end_minutes_ampm = latepoint_am_or_pm(end_minutes);
          timeslot_label+= ' - ' + latepoint_minutes_to_hours_and_minutes(end_minutes)+' '+end_minutes_ampm;
        }
        timeslot_label = timeslot_label.trim();
        $timeslots.append('<div class="'+timeslot_class+'" data-minutes="' + current_minutes + '"><span class="dp-label">'+ timeslot_label +'</span>'+tick_html+'</div>');

        prev_minutes = current_minutes;
      });
    }else{
      // No working hours this day
      $timeslots.append('<div class="not-working-message">' + latepoint_helper.msg_not_available + "</div>");
    }
  }




  function os_init_timeslots(){
    $('.dp-timeslot').on('click', function(){
      var $booking_form_wrapper = $(this).closest('.latepoint-booking-form-element');
      if($(this).hasClass('is-booked') || $(this).hasClass('is-off')){
        // Show error message that you cant select a booked period
      }else{
        if($(this).hasClass('selected')){
          $(this).removeClass('selected');
          $(this).find('.dp-success-label').remove();
          $booking_form_wrapper.find('.latepoint_start_time').val('');
          hide_next_btn($booking_form_wrapper);
          latepoint_update_summary_field($booking_form_wrapper, 'time', '');
        }else{
          $booking_form_wrapper.find('.dp-timeslot.selected').removeClass('selected').find('.dp-success-label').remove();
          var selected_timeslot_time = $(this).find('.dp-label').html();
          $(this).addClass('selected').find('.dp-label').html('<span class="dp-success-label">' + $booking_form_wrapper.find('.latepoint-form').data('selected-label') + '</span>' + selected_timeslot_time);
          $booking_form_wrapper.find('.latepoint_start_time').val($(this).data('minutes'));
          show_next_btn($booking_form_wrapper);
          latepoint_update_summary_field($booking_form_wrapper, 'time', selected_timeslot_time);
        }
      }
      return false;
    });
  }

  function os_init_monthly_calendar_navigation(){
    $('.os-month-next-btn').on('click', function(){
      var $booking_form_wrapper = $(this).closest('.latepoint-booking-form-element');
      var next_month_route_name = $(this).data('route');
      if($booking_form_wrapper.find('.os-monthly-calendar-days-w.active + .os-monthly-calendar-days-w').length){
        $booking_form_wrapper.find('.os-monthly-calendar-days-w.active').removeClass('active').next('.os-monthly-calendar-days-w').addClass('active');
        latepoint_calendar_set_month_label($booking_form_wrapper);
      }else{
        // TODO add condition to check maximum number months to call into the future
        if(true){
          var $btn = $(this);
          $btn.addClass('os-loading');
          var $calendar_element = $booking_form_wrapper.find('.os-monthly-calendar-days-w').last();
          var calendar_year = $calendar_element.data('calendar-year');
          var calendar_month = $calendar_element.data('calendar-month');
          if(calendar_month == 12){
            calendar_year = calendar_year + 1;
            calendar_month = 1;
          }else{
            calendar_month = calendar_month + 1;
          }
          var booking_info = { target_date_string: calendar_year + '-' + calendar_month + '-1', 
                                location_id: $booking_form_wrapper.find('.latepoint_location_id').val(), 
                                agent_id: $booking_form_wrapper.find('.latepoint_agent_id').val(), 
                                service_id: $booking_form_wrapper.find('.latepoint_service_id').val(),
                                duration: $booking_form_wrapper.find('input[name="booking[duration]"]').val(),
                                total_attendies: $booking_form_wrapper.find('input[name="booking[total_attendies]"]').val() };
          var data = { action: 'latepoint_route_call', route_name: next_month_route_name, params: booking_info, layout: 'none', return_format: 'json' }
          $.ajax({
            type : "post",
            dataType : "json",
            url : latepoint_helper.ajaxurl,
            data : data,
            success: function(data){
              $btn.removeClass('os-loading');
              if(data.status === "success"){
                $booking_form_wrapper.find('.os-months').append(data.message);
                $booking_form_wrapper.find('.os-monthly-calendar-days-w.active').removeClass('active').next('.os-monthly-calendar-days-w').addClass('active');
                latepoint_calendar_set_month_label($booking_form_wrapper);
              }else{
                // console.log(data.message);
              }
            }
          });
        }
      }
      latepoint_calendar_show_or_hide_prev_next_buttons($booking_form_wrapper);
      return false;
    });
    $('.os-month-prev-btn').on('click', function(){
      var $booking_form_wrapper = $(this).closest('.latepoint-booking-form-element');
      if($booking_form_wrapper.find('.os-monthly-calendar-days-w.active').prev('.os-monthly-calendar-days-w').length){
        $booking_form_wrapper.find('.os-monthly-calendar-days-w.active').removeClass('active').prev('.os-monthly-calendar-days-w').addClass('active');
        latepoint_calendar_set_month_label($booking_form_wrapper);
      }
      latepoint_calendar_show_or_hide_prev_next_buttons($booking_form_wrapper);
      return false;
    });
  }

  function latepoint_calendar_set_month_label($booking_form_wrapper){
    $booking_form_wrapper.find('.os-current-month-label .current-year').text($booking_form_wrapper.find('.os-monthly-calendar-days-w.active').data('calendar-year'));
    $booking_form_wrapper.find('.os-current-month-label .current-month').text($booking_form_wrapper.find('.os-monthly-calendar-days-w.active').data('calendar-month-label'));
  }


  function latepoint_calendar_show_or_hide_prev_next_buttons($booking_form_wrapper){
    $booking_form_wrapper.find('.os-current-month-label .current-year').text($booking_form_wrapper.find('.os-monthly-calendar-days-w.active .os-monthly-calendar-days').data('calendar-year'));
    $booking_form_wrapper.find('.os-current-month-label .current-month').text($booking_form_wrapper.find('.os-monthly-calendar-days-w.active .os-monthly-calendar-days').data('calendar-month-label'));

    if($booking_form_wrapper.find('.os-monthly-calendar-days-w.active').prev('.os-monthly-calendar-days-w').length){
      $booking_form_wrapper.find('.os-month-prev-btn').removeClass('disabled');
    }else{
      $booking_form_wrapper.find('.os-month-prev-btn').addClass('disabled');
    }
  }


  function latepoint_init_step_datepicker(){
    os_init_timeslots();
    os_init_monthly_calendar_navigation();
    $('.os-months').on('click', '.os-day', function(){
      if($(this).hasClass('os-day-passed')) return false;
      if($(this).hasClass('os-not-in-allowed-period')) return false;
      var $booking_form_wrapper = $(this).closest('.latepoint-booking-form-element');
      $booking_form_wrapper.find('.os-day.selected').removeClass('selected');
      day_timeslots($(this));
      os_init_timeslots();
      $('.times-header span').text($(this).data('nice-date'));
      $booking_form_wrapper.find('.time-selector-w').slideDown(200, function(){
        var $scrollable_wrapper = $booking_form_wrapper.find('.latepoint-body');
        $scrollable_wrapper.stop().animate({
          scrollTop: $scrollable_wrapper[0].scrollHeight
        }, 200);
      });
      $booking_form_wrapper.find('.latepoint_start_date').val($(this).data('date'));
      latepoint_update_summary_field($booking_form_wrapper, 'date', $(this).data('nice-date'));

      $booking_form_wrapper.find('.latepoint_start_time').val('');
      hide_next_btn($booking_form_wrapper);
      return false;
    });
  }

  function latepoint_init_paypal_button($booking_form_element){
    $booking_form_element.find('.lp-paypal-btn-trigger').html('');
    if(latepoint_helper.is_braintree_paypal_active){
      OsPaymentsBraintree.create_paypal_button($booking_form_element.find('.lp-paypal-btn-trigger')[0]);
    }
    if(latepoint_helper.is_paypal_native_active){
      OsPaymentsPaypal.create_paypal_button($booking_form_element.find('.lp-paypal-btn-trigger')[0]);
    }
    $('.lp-paypal-demo-mode-trigger').on('click', function(){
      $(this).closest('.latepoint-form').submit();
    });
  }


  function latepoint_init_cc_form($booking_form_element = false){
    if($booking_form_element){
      if(!$booking_form_element.find('.lp-card-w').length) return;
    }else{
      if(!$('.lp-card-w').length) return;
    }
    if(latepoint_helper.is_braintree_active){
      OsPaymentsBraintree.init_cc_form();
    }
    if(latepoint_helper.is_stripe_active){
      latepoint_init_stripe_form();
    }
  }

  function latepoint_init_step_verify($booking_form_element = false){
    if(!$booking_form_element) return;

    $booking_form_element.closest('.latepoint-summary-is-open').removeClass('latepoint-summary-is-open');
  }


  function latepoint_init_step_payment($booking_form_element = false){
    if($booking_form_element.find('.step-payment-w').data('full-amount') > 0){
      latepoint_update_summary_field($booking_form_element, 'price', latepoint_format_price($booking_form_element.find('.step-payment-w').data('full-amount')));
    }
    if($booking_form_element && ($booking_form_element.find('.step-payment-w').data('default-portion') == 'deposit')){
      $booking_form_element.find('input[name="booking[payment_portion]"]').val('deposit');
    }
    latepoint_init_cc_form($booking_form_element);

    if($booking_form_element && ($booking_form_element.find('.step-payment-w').data('current-payment-step') == 'lp-show-paypal')){
      latepoint_init_paypal_button($booking_form_element);
      hide_next_btn($booking_form_element);
    }

    $('.latepoint-booking-form-element .coupon-code-input-submit').on('click', function(e){
      latepoint_apply_coupon($(this).closest('.coupon-code-input-w').find('.coupon-code-input'));
      return false;
    });

    $('.latepoint-booking-form-element input.coupon-code-input').on('keyup', function(e){
      if(e.which === 13){
        latepoint_apply_coupon($(this));
        return false;
      }
    });


    $('.latepoint-booking-form-element .coupon-code-trigger-w a').on('click', function(e){
      $(this).closest('.payment-total-info').addClass('entering-coupon').find('.coupon-code-input').focus();
      return false;
    });


    $('.latepoint-booking-form-element .lp-payment-trigger-locally').on('click', function(e){
      var $booking_form_element = $(this).closest('.latepoint-booking-form-element');
      $booking_form_element.find('input[name="booking[payment_method]"]').val($(this).data('method'));
      $booking_form_element.find('input[name="booking[payment_portion]"]').val('');
      show_next_btn($booking_form_element);
    });

    $('.latepoint-booking-form-element .lp-payment-trigger-method-selector').on('click', function(e){
      var $booking_form_element = $(this).closest('.latepoint-booking-form-element');
      var $payment_step = $booking_form_element.find('.step-payment-w');
      var current_payment_step_class = $payment_step.data('current-payment-step');
      var new_current_payment_step_class = 'lp-show-pay-methods';

      $payment_step.removeClass(current_payment_step_class)
                  .addClass(new_current_payment_step_class)
                  .data('current-payment-step', new_current_payment_step_class)
                  .data('prev-payment-step', current_payment_step_class);
      hide_next_btn($booking_form_element);
      show_prev_btn($booking_form_element);
      return false;
    });

    $('.latepoint-booking-form-element .lp-payment-trigger-cc').on('click', function(e){
      var $booking_form_element = $(this).closest('.latepoint-booking-form-element');
      $booking_form_element.find('input[name="booking[payment_method]"]').val($(this).data('method'));

      var $payment_step = $booking_form_element.find('.step-payment-w');
      var current_payment_step_class = $payment_step.data('current-payment-step');
      var new_current_payment_step_class = 'lp-show-pay-portion-selection';

      if(!$booking_form_element.find('.lp-payment-portion-selection-w').length){
        // no deposits allowed
        new_current_payment_step_class = 'lp-show-card';
        show_next_btn($booking_form_element);
      }else{
        hide_next_btn($booking_form_element);
      }
      show_prev_btn($booking_form_element);
      $payment_step.removeClass(current_payment_step_class)
                  .addClass(new_current_payment_step_class)
                  .data('current-payment-step', new_current_payment_step_class)
                  .data('prev-payment-step', current_payment_step_class);
    });

    $('.latepoint-booking-form-element .lp-payment-trigger-paypal').on('click', function(e){
      var $booking_form_element = $(this).closest('.latepoint-booking-form-element');
      $booking_form_element.find('input[name="booking[payment_method]"]').val($(this).data('method'));

      var $payment_step = $booking_form_element.find('.step-payment-w');
      var current_payment_step_class = $payment_step.data('current-payment-step');
      var new_current_payment_step_class = 'lp-show-pay-portion-selection';

      if(!$booking_form_element.find('.lp-payment-portion-selection-w').length){
        // no deposits allowed
        latepoint_init_paypal_button($booking_form_element);
        new_current_payment_step_class = 'lp-show-paypal';
      }

      $payment_step.removeClass(current_payment_step_class)
                  .addClass(new_current_payment_step_class)
                  .data('current-payment-step', new_current_payment_step_class)
                  .data('prev-payment-step', current_payment_step_class);

      hide_next_btn($booking_form_element);
      show_prev_btn($booking_form_element);
    });

    $('.latepoint-booking-form-element .lp-trigger-payment-portion-selector').on('click', function(e){
      var $booking_form_element = $(this).closest('.latepoint-booking-form-element');
      var portion = jQuery(this).data('portion');
      $booking_form_element.find('input[name="booking[payment_portion]"]').val(portion);
      if(portion == 'deposit'){
        $booking_form_element.find('.payment-total-info').addClass('paying-deposit');
      }else{
        $booking_form_element.find('.payment-total-info').removeClass('paying-deposit');
      }

      var $payment_step = $booking_form_element.find('.step-payment-w');
      var current_payment_step_class = $payment_step.data('current-payment-step');
      var new_current_payment_step_class = 'lp-show-card';
      
      if($booking_form_element.find('input[name="booking[payment_method]"]').val() == 'card'){
        // card
        show_next_btn($booking_form_element);
      }else{
        // paypal
        latepoint_init_paypal_button($booking_form_element);
        new_current_payment_step_class = 'lp-show-paypal';
        hide_next_btn($booking_form_element);
      }
      show_prev_btn($booking_form_element);

      $payment_step.removeClass(current_payment_step_class)
                  .addClass(new_current_payment_step_class)
                  .data('current-payment-step', new_current_payment_step_class)
                  .data('prev-payment-step', current_payment_step_class);
    });


  }


  function latepoint_init_step_selectable_items(){
    $('.os-selectable-items .os-selectable-item').off('click', latepoint_selectable_item_clicked);
    $('.os-selectable-items .os-selectable-item').on('click', latepoint_selectable_item_clicked);
  }

  function latepoint_init_step_items_with_price(){
    $('.os-selectable-items .os-priced-item').off('click', latepoint_priced_item_clicked);
    $('.os-selectable-items .os-priced-item').on('click', latepoint_priced_item_clicked);
  }

  function latepoint_priced_item_clicked(){
    var $booking_form_wrapper = $(this).closest('.latepoint-booking-form-element');
    var operator = '=';
    if($(this).hasClass('os-allow-multiselect')){
      operator = ($(this).hasClass('selected')) ? '+' : '-';
    }
    latepoint_update_booking_component_price($(this).data('item-price'), $(this).data('priced-item-type'), $booking_form_wrapper, operator);
    return false;
  }

  function latepoint_calculate_total_price($booking_form_wrapper){
    var total_price = 0;
    $booking_form_wrapper.find('.latepoint-priced-component').each(function(){
      total_price+= Number($(this).val());
    });
    $booking_form_wrapper.find('.latepoint_total_price').val(total_price);
    if(total_price > 0){
      if($booking_form_wrapper.find('.latepoint_total_attendies').val() > 1) total_price = (total_price * $booking_form_wrapper.find('.latepoint_total_attendies').val()).toFixed(2);
      total_price = latepoint_format_price(total_price);
      latepoint_update_summary_field($booking_form_wrapper, 'price', total_price);
    }else{
      latepoint_update_summary_field($booking_form_wrapper, 'price', '');
    }
  }

  function latepoint_update_booking_component_price(amount, item_type, $booking_form_wrapper, operator){
    var item_type_class = '.latepoint_' + item_type + '_price';
    var price = Number($('.latepoint-priced-component' + item_type_class).val()) * 100;
    amount = Number(amount) * 100;
    if(operator == '='){
      price = amount;
    }else{
      price = (operator == '+') ? price + amount : price - amount;
    }
    price = price / 100;
    $('.latepoint-priced-component' + item_type_class).val(price);
    latepoint_calculate_total_price($booking_form_wrapper)
  }

  function latepoint_selectable_item_clicked(){
    var $booking_form_wrapper = $(this).closest('.latepoint-booking-form-element');
    var summary_value = '';
    if($(this).hasClass('os-allow-multiselect')){
      $(this).toggleClass('selected');
      var ids = $(this).closest('.os-selectable-items').find('.os-selectable-item.selected').map(function(){ return $(this).data('item-id'); }).get();
      $booking_form_wrapper.find($(this).data('id-holder')).val(ids);
      summary_value = String($(this).closest('.os-selectable-items').find('.os-selectable-item.selected').map(function(){ return (' ' + $(this).data('summary-value')); }).get()).trim();
      show_next_btn($booking_form_wrapper);
    }else{
      $(this).closest('.os-selectable-items').find('.os-selectable-item.selected').removeClass('selected');
      $(this).addClass('selected');
      $booking_form_wrapper.find($(this).data('id-holder')).val($(this).data('item-id'));
      summary_value = $(this).data('summary-value');
      if($(this).data('os-call-func')){
        window[$(this).data('os-call-func')]($(this));
      }
      if($(this).data('activate-sub-step')){
        window[$(this).data('activate-sub-step')]($(this));
      }else{
        trigger_next_or_show_next_button($booking_form_wrapper);
      }
    }
    latepoint_update_summary_field($booking_form_wrapper, $(this).data('summary-field-name'), summary_value);
  }


  function latepoint_format_price(price){
    return latepoint_helper.currency_symbol_before + String(price) + latepoint_helper.currency_symbol_after;
  }


  function latepoint_init_step_services(){
    $('.quantity-selector-input').on('change', function(){
      var $booking_form_wrapper = $(this).closest('.latepoint-booking-form-element');
      $booking_form_wrapper.find('.latepoint_total_attendies').val($(this).val());
      latepoint_update_summary_field($booking_form_wrapper, 'total-attendies', $(this).val());
      latepoint_calculate_total_price($booking_form_wrapper);
    });
    $('.quantity-selector').on('click', function(){
      var add_value = ($(this).hasClass('quantity-selector-plus')) ? 1 : -1;
      var max_capacity = $(this).closest('.quantity-selector-w').data('max-capacity');
      var current_value = $(this).closest('.quantity-selector-w').find('input.quantity-selector-input').val();
      var new_value = (Number(current_value) > 0) ? Math.max((Number(current_value) + add_value), 1) : 1;
      new_value = Math.min(Number(max_capacity), new_value);
      $(this).closest('.latepoint-booking-form-element').find('.latepoint_total_attendies').val(new_value);
      $(this).closest('.quantity-selector-w').find('input').val(new_value).trigger('change');
      return false;
    });
    $('.os-service-category-info').on('click', function(){
      var $booking_form_wrapper = $(this).closest('.latepoint-booking-form-element');
      show_prev_btn($booking_form_wrapper);
      $(this).closest('.step-services-w').addClass('selecting-service-category');
      var $category_wrapper = $(this).closest('.os-service-category-w');
      var $main_parent = $(this).closest('.os-service-categories-main-parent');
      if($category_wrapper.hasClass('selected')){
        $category_wrapper.removeClass('selected');
        if($category_wrapper.parent().closest('.os-service-category-w').length){
          $category_wrapper.parent().closest('.os-service-category-w').addClass('selected');
        }else{
          $main_parent.removeClass('show-selected-only');
        }
      }else{
        $main_parent.find('.os-service-category-w.selected').removeClass('selected');
        $main_parent.addClass('show-selected-only');
        $category_wrapper.addClass('selected');
      }
      return false;
    });
  }


  function trigger_next_or_show_next_button($booking_form_wrapper){
    if(!latepoint_helper.manual_next_step || latepoint_helper.manual_next_step == 'off'){
      $booking_form_wrapper.find('.latepoint_step_direction').val('next');
      $booking_form_wrapper.find('.latepoint-form').submit();
    }else{
      show_next_btn($booking_form_wrapper);
    }
  }

  function latepoint_init_step_locations(){
  }

  function latepoint_init_step_agents(){
    $('.os-items .os-item-details-btn').on('click', function(){
      var $booking_form_wrapper = $(this).closest('.latepoint-booking-form-element');
      var agent_id = $(this).data('agent-id');
      $booking_form_wrapper.find('.os-agent-bio-popup.active').removeClass('active');
      $booking_form_wrapper.find('#osAgentBioPopup' + agent_id).addClass('active');
      return false;
    });
    $('.os-agent-bio-close').on('click', function(){
      $(this).closest('.os-agent-bio-popup').removeClass('active');
      return false;
    });
  }


  function latepoint_init_step_confirmation(){

    $('.latepoint-booking-form-element').on('click', '.set-customer-password-btn', function(){
      var $btn = $(this);
      var $booking_form_wrapper = $(this).closest('.latepoint-booking-form-element');

      $btn.addClass('os-loading');
      var params = { account_nonse: $('input[name="account_nonse"]').val(), password: $('input[name="customer[password]"]').val(), password_confirmation: $('input[name="customer[password_confirmation]"]').val()}
      var data = { action: 'latepoint_route_call', route_name: $(this).data('btn-action'), params: $.param(params), layout: 'none', return_format: 'json' }
      $.ajax({
        type : "post",
        dataType : "json",
        url : latepoint_helper.ajaxurl,
        data : data,
        success: function(data){
          $btn.removeClass('os-loading');
          if(data.status === "success"){
            $booking_form_wrapper.find('.step-confirmation-set-password').html('').hide();
            $booking_form_wrapper.find('.confirmation-cabinet-info').show();
          }else{
            latepoint_show_message_inside_element(data.message, $booking_form_wrapper.find('.step-confirmation-set-password'), 'error');
          }
        }
      });
      return false;
    });

    $('.latepoint-booking-form-element').on('click', '.qr-show-trigger', function(){
      var $booking_form_wrapper = $(this).closest('.latepoint-booking-form-element');
      $booking_form_wrapper.find('.qr-code-on-confirmation').addClass('show-vevent-qr-code');
      return false;
    });

    $('.latepoint-booking-form-element').on('click', '.show-set-password-fields', function(){
      var $booking_form_wrapper = $(this).closest('.latepoint-booking-form-element');

      $booking_form_wrapper.find('.step-confirmation-set-password').show();
      $booking_form_wrapper.find('#customer_password').focus();
      $(this).closest('.info-box').hide();
      return false;
    });
  }


  function latepoint_init_customer_dashboard_login(){
    if($('.latepoint-login-form-w #facebook-signin-btn').length && $('.latepoint-login-form-w').length){
      $('.latepoint-login-form-w #facebook-signin-btn').on('click', function(){
        var $login_form_wrapper = $(this).closest('.latepoint-login-form-w');
        FB.login(function(response){
          if (response.status === 'connected' && response.authResponse) {
            var params = { token: response.authResponse.accessToken};
            var data = { action: 'latepoint_route_call', route_name: $login_form_wrapper.find('#facebook-signin-btn').data('login-action'), params: $.param(params), layout: 'none', return_format: 'json' };
            latepoint_step_content_change_start($login_form_wrapper);
            $.ajax({
              type : "post",
              dataType : "json",
              url : latepoint_helper.ajaxurl,
              data : data,
              success: function(data){
                if(data.status === "success"){
                  location.reload();
                }else{
                  latepoint_show_message_inside_element(data.message, $login_form_wrapper);
                  latepoint_step_content_change_end(false, $login_form_wrapper);
                }
              }
            });
          } else {
            
          }
        }, {scope: 'public_profile,email'});
      });
    }

    if($('.latepoint-login-form-w #google-signin-btn').length && $('.latepoint-login-form-w').length){
      // INIT GOOGLE LOGIN
      var googleUser = {};

      var $login_form_wrappers = $('.latepoint-login-form-w');
      $login_form_wrappers.each(function(){
        var $login_form_wrapper = $(this);
        gapi.load('auth2', function(){
          // Retrieve the singleton for the GoogleAuth library and set up the client.
          var auth2 = gapi.auth2.init({
            client_id: $login_form_wrapper.find('meta[name=google-signin-client_id]').attr("content"),
            cookiepolicy: 'single_host_origin',
            // Request scopes in addition to 'profile' and 'email'
            //scope: 'additional_scope'
          });

          auth2.attachClickHandler($login_form_wrapper.find('#google-signin-btn')[0], {},
            function(googleUser) {
              var params = { token: googleUser.getAuthResponse().id_token};
              var data = { action: 'latepoint_route_call', route_name: $login_form_wrapper.find('#google-signin-btn').data('login-action'), params: $.param(params), layout: 'none', return_format: 'json' };
              latepoint_step_content_change_start($login_form_wrapper);
              $.ajax({
                type : "post",
                dataType : "json",
                url : latepoint_helper.ajaxurl,
                data : data,
                success: function(data){
                  if(data.status === "success"){
                    location.reload();
                  }else{
                    latepoint_show_message_inside_element(data.message, $login_form_wrapper);
                    latepoint_step_content_change_end(false, $login_form_wrapper);
                  }
                }
              });
            }, function(error) {
              // console.log(JSON.stringify(error, undefined, 2));
            });
        });
      });
    }
  }


  function latepoint_init_step_contact(){
    latepoint_init_facebook_login();
    latepoint_init_google_login();
    latepoint_init_form_masks();

    $('.step-contact-w').each(function(){
      var customer_name = $(this).find('input[name="customer[first_name]"]').val() + ' ' + $(this).find('input[name="customer[last_name]"]').val();
      customer_name = customer_name.trim();
      latepoint_update_summary_field($(this).closest('.latepoint-booking-form-element'), 'customer', customer_name);
    });

    $('.step-contact-w').on('keyup', 'input[name="customer[first_name]"], input[name="customer[last_name]"]', function(){
      var $booking_form_wrapper = $(this).closest('.latepoint-booking-form-element');
      latepoint_update_summary_field($booking_form_wrapper, 'customer', $booking_form_wrapper.find('input[name="customer[first_name]"]').val() + ' ' + $booking_form_wrapper.find('input[name="customer[last_name]"]').val());
    });

    $('.step-contact-w').on('keyup', '.os-form-control.required', function(){
      var $booking_form_wrapper = $(this).closest('.latepoint-booking-form-element');
      if(latepoint_validate_fields($booking_form_wrapper.find('.step-contact-w .os-form-control.required'))){
      }else{
      }
    });

    // Init Logout button
    $('.step-customer-logout-btn').on('click', function(){
      var $booking_form_wrapper = $(this).closest('.latepoint-booking-form-element');
      var data = { action: 'latepoint_route_call', route_name: $(this).data('btn-action'), layout: 'none', return_format: 'json' }
      latepoint_step_content_change_start($booking_form_wrapper);
      $.ajax({
        type : "post",
        dataType : "json",
        url : latepoint_helper.ajaxurl,
        data : data,
        success: function(data){
          latepoint_reload_step($booking_form_wrapper);
        }
      });
      return false;
    });

    // Init Login Existing Customer Button
    $('.step-login-existing-customer-btn').on('click', function(){
      var $booking_form_wrapper = $(this).closest('.latepoint-booking-form-element');
      var params = { email: $booking_form_wrapper.find('.os-step-existing-customer-login-w input[name="customer_login[email]"]').val(), password: $booking_form_wrapper.find('.os-step-existing-customer-login-w input[name="customer_login[password]"]').val()}
      var data = { action: 'latepoint_route_call', route_name: $(this).data('btn-action'), params: $.param(params), layout: 'none', return_format: 'json' }
      latepoint_step_content_change_start($booking_form_wrapper);
      $.ajax({
        type : "post",
        dataType : "json",
        url : latepoint_helper.ajaxurl,
        data : data,
        success: function(data){
            if(data.status === "success"){
              latepoint_reload_step($booking_form_wrapper);
            }else{
              latepoint_show_message_inside_element(data.message, $booking_form_wrapper.find('.os-step-existing-customer-login-w'));
              latepoint_step_content_change_end(false, $booking_form_wrapper);
            }
        }
      });
      return false;
    });
  }

  function latepoint_step_content_change_start($booking_form_wrapper){
    $booking_form_wrapper.removeClass('step-content-loaded').addClass('step-content-loading');
  }

  // TODO
  function latepoint_step_content_change_end(new_content, $booking_form_wrapper){
    if(new_content) $booking_form_wrapper.find('.latepoint-body .latepoint-step-content').replaceWith(new_content);
    $booking_form_wrapper.removeClass('step-content-loading').addClass('step-content-mid-loading');
    setTimeout(function(){
      $booking_form_wrapper.removeClass('step-content-mid-loading').addClass('step-content-loaded');
    }, 50);
  }

  function latepoint_init_facebook_login(){
    if(!$('#facebook-signin-btn').length || !$('.latepoint-booking-form-element').length) return;
    $('#facebook-signin-btn').on('click', function(){
      var $booking_form_wrapper = $(this).closest('.latepoint-booking-form-element');
      FB.login(function(response){
        if (response.status === 'connected' && response.authResponse) {
          var params = { token: response.authResponse.accessToken};
          var data = { action: 'latepoint_route_call', route_name: $booking_form_wrapper.find('#facebook-signin-btn').data('login-action'), params: $.param(params), layout: 'none', return_format: 'json' };
          latepoint_step_content_change_start($booking_form_wrapper);
          $.ajax({
            type : "post",
            dataType : "json",
            url : latepoint_helper.ajaxurl,
            data : data,
            success: function(data){
              if(data.status === "success"){
                latepoint_reload_step($booking_form_wrapper);
              }else{
                latepoint_show_message_inside_element(data.message, $booking_form_wrapper.find('.os-step-existing-customer-login-w '));
                latepoint_step_content_change_end(false, $booking_form_wrapper);
              }
            }
          });
        } else {
          
        }
      }, {scope: 'public_profile,email'});
    });
  }

  function latepoint_init_google_login(){
    if(!$('#google-signin-btn').length || !$('.latepoint-booking-form-element').length) return;
    var googleUser = {};

    var $booking_form_wrappers = $('.latepoint-booking-form-element');
    $booking_form_wrappers.each(function(){
      var $booking_form_wrapper = $(this);
      gapi.load('auth2', function(){
        // Retrieve the singleton for the GoogleAuth library and set up the client.
        var auth2 = gapi.auth2.init({
          client_id: $booking_form_wrapper.find('meta[name=google-signin-client_id]').attr("content"),
          cookiepolicy: 'single_host_origin',
          // Request scopes in addition to 'profile' and 'email'
          //scope: 'additional_scope'
        });

        auth2.attachClickHandler($booking_form_wrapper.find('#google-signin-btn')[0], {},
          function(googleUser) {
            var params = { token: googleUser.getAuthResponse().id_token};
            var data = { action: 'latepoint_route_call', route_name: $booking_form_wrapper.find('#google-signin-btn').data('login-action'), params: $.param(params), layout: 'none', return_format: 'json' };
            latepoint_step_content_change_start($booking_form_wrapper);
            $.ajax({
              type : "post",
              dataType : "json",
              url : latepoint_helper.ajaxurl,
              data : data,
              success: function(data){
                if(data.status === "success"){
                  latepoint_reload_step($booking_form_wrapper);
                }else{
                  latepoint_show_message_inside_element(data.message, $booking_form_wrapper.find('.os-step-existing-customer-login-w '));
                  latepoint_step_content_change_end(false, $booking_form_wrapper);
                }
              }
            });
          }, function(error) {
            // console.log(JSON.stringify(error, undefined, 2));
          });
      });
    });
  }

  function latepoint_change_step_desc($booking_form_wrapper, step_name){
    $booking_form_wrapper.removeClass('step-changed').addClass('step-changing');
    setTimeout(function(){
      // Progress bar
      var $step_progress = $booking_form_wrapper.find('.latepoint-progress li[data-step-name="' + step_name + '"]');
      $step_progress.addClass('active').addClass('complete').prevAll().addClass('complete').removeClass('active');
      $step_progress.nextAll().removeClass('complete').removeClass('active');
      // Side panel
      var side_panel_desc = $booking_form_wrapper.find('.latepoint-step-desc-library[data-step-name="' + step_name + '"]').html();
      $booking_form_wrapper.find('.latepoint-step-desc').html(side_panel_desc);

      // Top header
      var top_header_desc = $booking_form_wrapper.find('.os-heading-text-library[data-step-name="' + step_name + '"]').html();
      $booking_form_wrapper.find('.os-heading-text').html(top_header_desc);
      setTimeout(function(){
          $booking_form_wrapper.removeClass('step-changing').addClass('step-changed');
      }, 50);
    }, 500);
  }


  function latepoint_progress_prev($booking_form_wrapper, step_name){
    var $step_progress = $booking_form_wrapper.find('.latepoint-progress li[data-step-name="' + step_name + '"]');
    $step_progress.addClass('active').addClass('complete').prevAll().addClass('complete').removeClass('active');
    $step_progress.nextAll().removeClass('complete').removeClass('active');
  }


  function latepoint_progress_next($booking_form_wrapper, step_name){
    var $step_progress = $booking_form_wrapper.find('.latepoint-progress li[data-step-name="' + step_name + '"]');
    $step_progress.addClass('active').addClass('complete').prevAll().addClass('complete').removeClass('active');
    $step_progress.nextAll().removeClass('complete').removeClass('active');
  }


  function latepoint_next_step_description($booking_form_wrapper, step_name){
    $booking_form_wrapper.removeClass('step-changed').addClass('step-changing');
    setTimeout(function(){
      $booking_form_wrapper.find('.latepoint-step-desc').html($booking_form_wrapper.find('.latepoint-step-desc-library.active').removeClass('active').next('.latepoint-step-desc-library').addClass('active').html());
      $booking_form_wrapper.find('.os-heading-text').html($booking_form_wrapper.find('.os-heading-text-library.active').removeClass('active').next('.os-heading-text-library').addClass('active').html());
      setTimeout(function(){
        $booking_form_wrapper.removeClass('step-changing').addClass('step-changed');
      }, 50);
    }, 500);
  }
  function latepoint_prev_step_description($booking_form_wrapper, step_name){
    $booking_form_wrapper.removeClass('step-changed').addClass('step-changing');
    setTimeout(function(){
      $booking_form_wrapper.find('.latepoint-step-desc').html($booking_form_wrapper.find('.latepoint-step-desc-library.active').removeClass('active').prev('.latepoint-step-desc-library').addClass('active').html());
      $booking_form_wrapper.find('.os-heading-text').html($booking_form_wrapper.find('.os-heading-text-library.active').removeClass('active').prev('.os-heading-text-library').addClass('active').html());
      setTimeout(function(){
        $booking_form_wrapper.removeClass('step-changing').addClass('step-changed');
      }, 50);
    }, 500);
  }


  function latepoint_validate_fields($fields){
    var is_valid = true;
    $fields.each(function(index){
      if($(this).val() == ''){
        is_valid = false;
        return false;
      }
    });
    return is_valid;
  }


  function latepoint_init_booking_form(){
    $('.latepoint-lightbox-close').on('click', function(){
      $('body').removeClass('latepoint-lightbox-active');
      $('.latepoint-lightbox-w').remove();
      return false;
    });


    $('.latepoint-customer-timezone-selector-w select').on('change', function(e){
      var $select_box = $(this);
      $select_box.closest('.latepoint-customer-timezone-selector-w').addClass('os-loading');
      var data = { action: 'latepoint_route_call', route_name: $(this).closest('.latepoint-customer-timezone-selector-w').data('route-name'), params: { timezone_name: $(this).val()}, layout: 'none', return_format: 'json'}
      $.ajax({
        type : "post",
        dataType : "json",
        url : latepoint_helper.ajaxurl,
        data : data,
        success: function(data){
          $select_box.closest('.latepoint-customer-timezone-selector-w').removeClass('os-loading');
          if(data.status === "success"){
            location.reload();
          }else{

          }
        }
      });
    });

    $('.latepoint-timezone-selector-w select').on('change', function(e){
      var $select_box = $(this);
      $select_box.closest('.latepoint-timezone-selector-w').addClass('os-loading');
      var data = { action: 'latepoint_route_call', route_name: $(this).closest('.latepoint-timezone-selector-w').data('route-name'), params: { timezone_name: $(this).val()}, layout: 'none', return_format: 'json'}
      var $booking_form_wrapper = $select_box.closest('.latepoint-booking-form-element');
      $booking_form_wrapper.removeClass('step-content-loaded').addClass('step-content-loading');
      $.ajax({
        type : "post",
        dataType : "json",
        url : latepoint_helper.ajaxurl,
        data : data,
        success: function(data){
          $select_box.closest('.latepoint-timezone-selector-w').removeClass('os-loading');
          $booking_form_wrapper.removeClass('step-content-loading');
          if(data.status === "success"){
            // reload datepicker if its the step
            if($select_box.closest('.latepoint-booking-form-element').hasClass('current-step-datepicker')){
              latepoint_reload_step($select_box.closest('.latepoint-booking-form-element'));
            }
          }else{

          }
        }
      });
    });

    if(!latepoint_helper.is_timezone_selected){
      const tzid = Intl.DateTimeFormat().resolvedOptions().timeZone;
      if(tzid){
        if(tzid != $('.latepoint-timezone-selector-w select').val()) $('.latepoint-timezone-selector-w select').val(tzid).change();
      }
    }

    $('.latepoint-booking-form-element .latepoint-form').on('submit', function(e){
      var $booking_form = $(this);
      var $booking_form_wrapper = $(this).closest('.latepoint-booking-form-element');
      e.preventDefault();
      var form_data = $booking_form.serialize();
      var data = { action: 'latepoint_route_call', route_name: $booking_form.data('route-name'), params: form_data, layout: 'none', return_format: 'json'}
      $booking_form_wrapper.removeClass('step-content-loaded').addClass('step-content-loading');
      $.ajax({
        type : "post",
        dataType : "json",
        url : latepoint_helper.ajaxurl,
        data : data,
        success: function(data){
          if(data.status === "success"){
            $booking_form_wrapper.find('.latepoint_current_step').val(data.step_name);
            $booking_form_wrapper.removeClass(function (index, className) { return (className.match (/(^|\s)current-step-\S+/g) || []).join(' '); }).addClass('current-step-' + data.step_name);
            setTimeout(function(){
              $booking_form_wrapper.removeClass('step-content-loading').addClass('step-content-mid-loading');
              $booking_form_wrapper.find('.latepoint-body').html(data.message);
              latepoint_init_step(data.step_name, $booking_form_wrapper);
              setTimeout(function(){
                $booking_form_wrapper.removeClass('step-content-mid-loading').addClass('step-content-loaded');
                $booking_form_wrapper.find('.latepoint-next-btn, .latepoint-prev-btn').removeClass('os-loading');
              }, 50);
            }, 500);

            if(data.is_pre_last_step){
              $booking_form_wrapper.find('.latepoint-next-btn span').text($booking_form_wrapper.find('.latepoint-next-btn').data('pre-last-step-label'));
            }else{
              $booking_form_wrapper.find('.latepoint-next-btn span').text($booking_form_wrapper.find('.latepoint-next-btn').data('label'));
            }
            if(data.is_last_step){
              $booking_form_wrapper.addClass('hidden-buttons').find('.latepoint-footer').remove();
              $booking_form_wrapper.find('.latepoint-progress').css( 'opacity', 0 );
              $booking_form_wrapper.closest('.latepoint-summary-is-open').removeClass('latepoint-summary-is-open');
              $booking_form_wrapper.addClass('is-final-step');
            }else{
              if(data.show_next_btn === true){
                show_next_btn($booking_form_wrapper);
              }else{
                hide_next_btn($booking_form_wrapper);
              }
              if(data.show_prev_btn === true){
                show_prev_btn($booking_form_wrapper);
              }else{
                hide_prev_btn($booking_form_wrapper);
              }
            }
            

            latepoint_change_step_desc($booking_form_wrapper, data.step_name);
          }else{
            $booking_form_wrapper.removeClass('step-content-loading').addClass('step-content-loaded');
            $booking_form_wrapper.find('.latepoint-next-btn, .latepoint-prev-btn').removeClass('os-loading');
            latepoint_show_message_inside_element(data.message, $booking_form_wrapper.find('.latepoint-body'));
          }
          $booking_form.find('.latepoint_step_direction').val('next');
        }
      });
    });

    

    $('.latepoint-booking-form-element').on('click', '.lp-option', function(){
      $(this).closest('.lp-options').find('.lp-option.selected').removeClass('selected');
      $(this).addClass('selected');
    });




    // Next Step button Click
    $('.latepoint-booking-form-element .latepoint-next-btn').on('click', function(e){
      if($(this).hasClass('disabled') || $(this).hasClass('os-loading')) return false;
      var $booking_form = $(this).closest('.latepoint-form');
      var $booking_form_wrapper = $booking_form.closest('.latepoint-booking-form-element');

      $booking_form.find('.latepoint_step_direction').val('next');
      if(($booking_form_wrapper.find('.latepoint_current_step').val() == 'payment') && ($booking_form_wrapper.find('input[name="booking[payment_method]"]').val() == 'card') && !latepoint_helper.demo_mode){
        if(latepoint_helper.is_stripe_active){
          latepoint_stripe_create_token($booking_form_wrapper);
        }
        if(latepoint_helper.is_braintree_active){
          OsPaymentsBraintree.create_token($booking_form_wrapper);
        }
      }else{
        $booking_form.submit();
      }
      $(this).addClass('os-loading');
      return false;
    });


    // Previous Step button Click
    $('.latepoint-booking-form-element .latepoint-prev-btn').on('click', function(e){
      if($(this).hasClass('disabled') || $(this).hasClass('os-loading')) return false;
      var $booking_form = $(this).closest('.latepoint-form');
      var $booking_form_wrapper = $booking_form.closest('.latepoint-booking-form-element');

      var current_step = $booking_form.find('.latepoint_current_step').val();

      if(current_step == 'locations'){
        $booking_form_wrapper.find('.latepoint_location_id').val('');
      }
      if(current_step == 'agents'){
        $booking_form_wrapper.find('.latepoint_agent_id').val('');
      }
      if(current_step == 'payment'){
        var $payment_step = $booking_form_wrapper.find('.step-payment-w');
        if($payment_step.length){
          $payment_step.find('.lp-option.selected').removeClass('selected');
          var current_payment_step_class = $payment_step.data('current-payment-step');
          var new_current_payment_step_class = $payment_step.data('prev-payment-step');
          if(new_current_payment_step_class){
            var new_prev_payment_step_class = latepoint_get_new_prev_payment_class($payment_step, new_current_payment_step_class);
            $payment_step.removeClass(current_payment_step_class)
                        .addClass(new_current_payment_step_class)
                        .data('current-payment-step', new_current_payment_step_class)
                        .data('prev-payment-step', new_prev_payment_step_class);
            hide_next_btn($booking_form_wrapper);
            return false;
          }
        }
      }
      if(current_step == 'services'){
        var $services_step = $booking_form_wrapper.find('.step-services-w');
        if($services_step.hasClass('selecting-service-duration')){
          $services_step.removeClass('selecting-service-duration');
          $services_step.find('.os-services > .os-item.selected').removeClass('selected');
          return false;
        }
        if($services_step.hasClass('selecting-total-attendies')){
          $services_step.removeClass('selecting-total-attendies');
          $services_step.find('.os-services > .os-item.selected').removeClass('selected');
          return false;
        }
        if($services_step.hasClass('selecting-service-category')){
          if($services_step.find('.os-service-category-w .os-service-category-w.selected').length){
            $services_step.find('.os-service-category-w .os-service-category-w.selected').parents('.os-service-category-w').addClass('selected').find('.os-service-category-w.selected').removeClass('selected');
          }else{
            $services_step.removeClass('selecting-service-category').find('.os-service-category-w.selected').removeClass('selected');
            $services_step.removeClass('selecting-service-category').find('.os-service-categories-holder.show-selected-only').removeClass('show-selected-only');
          }
          return false;
        }
        $booking_form_wrapper.find('.latepoint_service_id').val('');
        $booking_form_wrapper.find('input[name="booking[duration]"]').val('');
        $booking_form_wrapper.find('input[name="booking[total_attendies]"]').val('');
      }

      latepoint_clear_step_vars($booking_form.find('.latepoint_current_step').val(), $booking_form);
      $booking_form.find('.latepoint_step_direction').val('prev');
      $booking_form.submit();
      $booking_form.closest('.latepoint-with-summary').addClass('latepoint-summary-is-open');
      $(this).addClass('os-loading');
      return false;
    });
  }

  // DOCUMENT READY
  $( function() {

    latepoint_init_customer_dashboard_login();

    if($('.latepoint-booking-form-element').length){
      latepoint_init_booking_form();
      $('.latepoint-booking-form-element').each(function(){
        latepoint_init_step($(this).find('.latepoint_current_step').val());
      });
    }

    $('.latepoint-request-booking-cancellation').on('click', function(){
      if(!confirm(latepoint_helper.cancel_booking_prompt)) return false;
      var $this = $(this);
      var $booking_box = $this.closest('.customer-booking');

      var route = $(this).data('route');
      var params = {id: $booking_box.data('id')};

      var data = { action: 'latepoint_route_call', route_name: route, params: params, layout: 'none', return_format: 'json' }
      $this.addClass('os-loading');
      $.ajax({
        type : "post",
        dataType : "json",
        url : latepoint_helper.ajaxurl,
        data : data,
        success: function(data){
          if(data.status === "success"){
            location.reload();
          }else{
            $this.removeClass('os-loading');
          }
        }
      });
      return false;
    });


    $('body').on('click', '.os-step-tabs .os-step-tab', function(){
      $(this).closest('.os-step-tabs').find('.os-step-tab').removeClass('active');
      $(this).addClass('active');
      var target = $(this).data('target');
      $(this).closest('.os-step-tabs-w').find('.os-step-tab-content').hide();
      $(target).show();
    });

    $('body').on('keyup', '.os-form-group .os-form-control', function(){
      if($(this).val()){
        $(this).closest('.os-form-group').addClass('has-value');
      }else{
        $(this).closest('.os-form-group').removeClass('has-value');
      }
    });

    $('.latepoint-tab-triggers').on('click', '.latepoint-tab-trigger', function(){
      var $tabs_wrapper = $(this).closest('.latepoint-tabs-w')
      $tabs_wrapper.find('.latepoint-tab-trigger.active').removeClass('active');
      $tabs_wrapper.find('.latepoint-tab-content').removeClass('active');
      $(this).addClass('active');
      $tabs_wrapper.find('.latepoint-tab-content' + $(this).data('tab-target')).addClass('active');
      return false;
    });


    // Main Button to trigger lightbox opening
    $('.latepoint-book-button, .os_trigger_booking').on('click', function(){
      var $this = $(this);
      var route = latepoint_helper.booking_button_route;
      var params = {};
      var restrictions = {};
      if($this.data('show-service-categories')) restrictions.show_service_categories = $this.data('show-service-categories');
      if($this.data('show-locations')) restrictions.show_locations = $this.data('show-locations');
      if($this.data('show-services')) restrictions.show_services = $this.data('show-services');
      if($this.data('show-agents')) restrictions.show_agents = $this.data('show-agents');
      if($this.data('selected-location')) restrictions.selected_location = $this.data('selected-location');
      if($this.data('selected-agent')) restrictions.selected_agent = $this.data('selected-agent');
      if($this.data('selected-service')) restrictions.selected_service = $this.data('selected-service');
      if($this.data('selected-service-category')) restrictions.selected_service_category = $this.data('selected-service-category');
      if($this.data('calendar-start-date')) restrictions.calendar_start_date = $this.data('calendar-start-date');

      if($.isEmptyObject(restrictions) == false) params.restrictions = restrictions;

      var data = { action: 'latepoint_route_call', route_name: route, params: params, layout: 'none', return_format: 'json' }
      $this.addClass('os-loading');
      $.ajax({
        type : "post",
        dataType : "json",
        url : latepoint_helper.ajaxurl,
        data : data,
        success: function(data){
          if(data.status === "success"){
            var lightbox_class = '';
            if($this.data('hide-summary') != 'yes') lightbox_class+= ' latepoint-with-summary';
            if($this.data('hide-side-panel') == 'yes') lightbox_class+= ' latepoint-hide-side-panel';
            latepoint_show_data_in_lightbox(data.message, lightbox_class);
            latepoint_init_booking_form();
            latepoint_init_step(data.step);
            $('body').addClass('latepoint-lightbox-active');
            $this.removeClass('os-loading');
            
          }else{
            $this.removeClass('os-loading');
            // console.log(data.message);
          }
        }
      });

      return false;
    });



  });


} )( jQuery );
